<template>
  <div class="navbar">
      <div class="navbar-left">
      <div class="logo" style="font-size: 10px;">
          <img :src="require('@/assets/logo.png')" alt="Takway.Ai" />
      </div>
      <div class="menu-items">
          <a href="#">Sweekar</a>
          <a href="#">About us</a>
      </div>
      </div>
      <div class="navbar-right">
      <div class="icon">
          <img :src="require('@/assets/Search.png')" class="icon_img"/>
      </div>
      <div class="icon">
          <img :src="require('@/assets/User.png')" class="icon_img" />
      </div>
      <div class="icon">
          <img :src="require('@/assets/Shopping cart.png')" class="icon_img" />
      </div>
      </div>
  </div>
</template>

<script>

export default {
name: 'NavBar'
}
</script>

<style scoped>
@font-face {
font-family: Noto Sans SC;
src: url('@/assets/font/NotoSansSC-Medium.ttf');
font-weight: normal;
font-style: normal;
}

.navbar {
display: flex;
justify-content: space-between;
align-items: center;
padding: 10px 20px;
background-color: white;
border-bottom: 1px solid #eee;
}

.navbar-left {
display: flex;
align-items: center;
padding-left: 15%;
}

.logo img {
height: 40px;
}

.menu-items {
display: flex;
margin-left: 20px;
font-family: Noto Sans SC;
font-size: 20px;
}

.menu-items a {
margin-right: 20px;
text-decoration: none;
color: black;
}

.navbar-right {
display: flex;
align-items: center;
padding-right: 15%;
}

.icon {
margin-left: 20px;
cursor: pointer;
}

.icon_img {
height: 22px;
width: 22px;
}

/* Media queries for mobile responsiveness */
@media screen and (max-width: 768px) {
.navbar {
  padding: 10px 10px;
}

.navbar-left {
  padding-left: 5%;
}

.menu-items {
  font-size: 16px;
  margin-left: 10px;
}

.menu-items a {
  margin-right: 10px;
}

.navbar-right {
  padding-right: 5%;
}

.icon_img {
  height: 18px;
  width: 18px;
}
}

@media screen and (max-width: 480px) {
.navbar {
  padding: 5px 5px;
}

.navbar-left {
  padding-left: 0;
}

.menu-items {
  font-size: 12px;
  margin-left: 5px;
}

.menu-items a {
  margin-right: 5px;
}

.navbar-right {
  padding-right: 0;
}

.icon_img {
  height: 14px;
  width: 14px;
}

.icon {
  margin-left: 5px;
}
}
</style>
