<template>
    <div class="container">
      <div class="carousel">
        <el-carousel :height="carouselHeight">
          <el-carousel-item v-for="(imgSrc, index) in carouselImages" :key="index" style="height: auto;">
            <img :src="require(`@/assets/${imgSrc}`)" class="carousel-img" />
          </el-carousel-item>
        </el-carousel>
      </div>
  
      <div v-for="(block, index) in contentBlocks" :key="index" class="content_block">
        <div class="title be_center">{{ block.title }}</div>
        <div v-if="block.description" class="be_center content">{{ block.description }}</div>
        <div class="be_center intro_pic">
          <img :src="require(`@/assets/${block.img}`)" class="intro-img" />
        </div>
      </div>
  
      <div class="content_block" style="margin-bottom: 100px;">
        <div class="title be_center" style="margin-bottom: 50px;">Subscribe to our emails</div>
        <div class="be_center intro_pic">
          <div class="input-container">
            <input type="text" placeholder="Email" />
            <span class="icon iconfont icon-youjiantou" @click="submit_email"></span>
          </div>
        </div>
      </div>
  
      <div class="bottom_policy">
        <el-row class="be_center content">
          <el-col :span="5">
            <img :src="require('@/assets/logo-bottom.png')" alt="Takway.Ai" class="bottom-logo" />
          </el-col>
          <el-col :span="14">
            <el-row class="bottom_links">
              <el-col :span="7">Privacy Policy</el-col>
              <el-col :span="7">Shipping Policy</el-col>
              <el-col :span="5">About us</el-col>
              <el-col :span="5">Contact us</el-col>
            </el-row>
          </el-col>
          <el-col :span="5">
            <img :src="require('@/assets/Social media.png')" alt="Takway.Ai" class="social-media" />
          </el-col>
        </el-row>
        <el-divider></el-divider>
        <div class="rights_text">
          All rights reserved in Takway.AI
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "HomePage",
    data() {
      return {
        carouselHeight: "",
        imageOriginalWidth: 2560,
        imageOriginalHeight: 1440,
        carouselImages: ["pic1.png", "pic2.png"],
        contentBlocks: [
          {
            title: "Lightweight & Portable",
            description:
              "Egg-sized and even lighter\nInterchangeable silicone shell\nEnjoy endless personal fun",
            img: "pic3.png",
          },
          { title: "Children's Smart Ally", img: "pic4.png" },
          { title: "Parents' Powerful Ally", img: "pic5.png" },
        ],
      };
    },
    mounted() {
      this.calculateCarouselHeight();
      window.addEventListener("resize", this.calculateCarouselHeight);
    },
    beforeDestroy() {
      window.removeEventListener("resize", this.calculateCarouselHeight);
    },
    methods: {
      calculateCarouselHeight() {
        const windowWidth = window.innerWidth;
        this.carouselHeight = `${(windowWidth / this.imageOriginalWidth) * this.imageOriginalHeight}px`;
      },
      submit_email() {
        // Implement email submission logic here
      },
    },
  };
  </script>
  
  <style scoped>
  @font-face {
    font-family: Noto Sans SC;
    src: url('@/assets/font/NotoSansSC-Regular.ttf');
    font-weight: normal;
    font-style: normal;
  }
  
  .container {
    width: 100%;
  }
  
  .carousel {
    text-align: center;
    margin: auto;
  }
  
  .carousel-img {
    width: 100%;
  }
  
  .be_center {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  .title {
    font-size: 3rem;
    font-weight: bolder;
    font-family: Noto Sans SC;
  }
  
  .content {
    font-size: 1.5rem;
    margin-top: 24px;
    white-space: pre-line;
  }
  
  .intro-img {
    width: 65%;
    margin-top: 100px;
  }
  
  .input-container {
    position: relative;
    width: 40%;
    display: flex;
  }
  
  .input-container input {
    width: 100%;
    padding: 20px;
    border: 3px solid black;
    border-radius: 10px;
    outline: none;
    font-size: 2rem;
  }
  
  .input-container .icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 2.5rem;
    cursor: pointer;
  }
  
  .bottom_policy {
    background-color: #f6f6f6;
    padding-top: 2vh;
    font-family: Noto Sans SC;
  }
  
  .bottom-logo {
    height: 3rem;
    margin-top: 5px;
  }
  
  .social-media {
    height: 2rem;
    margin-top: 5px;
  }
  
  .rights_text {
    text-align: center;
    padding: 10px 0 30px;
    background-color: #f6f6f6;
    font-size: 1rem;
    color: #808080;
  }
  
  .content_block {
    margin-top: 100px;
  }
  
  @media (max-width: 768px) {
    .title {
      font-size: 2rem;
    }
  
    .content {
      font-size: 1.2rem;
    }
  
    .intro-img {
      width: 85%;
      margin-top: 50px;
    }
  
    .input-container {
      width: 80%;
    }
  
    .input-container input {
      font-size: 1.5rem;
    }
  
    .input-container .icon {
      font-size: 2rem;
    }
  
    .bottom-logo {
      height: 2rem;
    }
  
    .social-media {
      height: 1.5rem;
    }
  
    .rights_text {
      font-size: 0.8rem;
    }
  }
  
  @media (max-width: 480px) {
    .title {
      font-size: 1.5rem;
    }
  
    .content {
      font-size: 1rem;
    }
  
    .intro-img {
      width: 90%;
      margin-top: 30px;
    }
  
    .input-container {
      width: 90%;
    }
  
    .input-container input {
      font-size: 1.2rem;
    }
  
    .input-container .icon {
      font-size: 1.5rem;
    }
  
    .bottom-logo {
      height: 1.2rem;
    }
  
    .social-media {
      height: 0.8rem;
    }
  
    .rights_text {
      font-size: 0.7rem;
    }

    .bottom_links{
        font-size:0.8rem
    }
  }
  </style>
  