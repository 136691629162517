import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);
import Hompage from '../components/Homepage/index.vue'

const routes = [
    {
        path:'/',
        name:'Homepage',
        component: Hompage
    }
];

const router = new VueRouter({
    mode: 'history',
    routes
});

export default router;