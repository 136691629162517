<template>
  <div id="app">
    <Navbar></Navbar>
    <router-view></router-view>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar/index.vue'
export default {
  components:{
    Navbar
  }
}
</script>

<style>
body, html {
    margin: 0;
    padding: 0;
}
</style>
